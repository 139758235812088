import createPlugin from '@@/create-plugin';

export default createPlugin({
    components: {
        'editor:tool': () => import('./components/tool'),
        'directive:collapse': () => import('./components/html/collapse'),
        'directive:tabs': () => import('./components/html/tabs'),
        'directive:tab': () => import('./components/html/tabs').then(({Tab})=>({default:Tab})),
        'directive:columns': () => import('./components/html/columns'),
        'directive:link': () => import('./components/html/link'),
        'directive:catalog': () => import('./components/html/catalog'),
        'block:card': () => import('./components/html/card'),
        'editor:block:card:edit': () => import('./components/tool/card').then(({ CardEdit }) => ({ default: CardEdit })),
        'activate:editor': () => import('./components/activate')
    },
});
